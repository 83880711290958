// --------------------------------------------------
// Opacity and opacity animations SASS mixins
// --------------------------------------------------

@mixin Opacity($value) {
    $IEValue: $value*100;
    opacity: $value;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$IEValue+")";
    -webkit-filter: alpha(opacity=$IEValue);
    -moz-filter: alpha(opacity=$IEValue);
    -o-filter: alpha(opacity=$IEValue);
    filter: alpha(opacity=$IEValue);
}

// shorthand
@mixin opacity($args...) {
    @include Opacity($args...);
}