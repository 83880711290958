@import "./mixins/responsive";
@import "./mixins/flexbox";
@import "./mixins/effects";
@import "./mixins/transitions";
@import "./mixins/opacity";
@import "./skin";

/* NTL HUGO THEME STYLES */

*,
*:before,
*:after {
    box-sizing: inherit;
}

*:focus {
    outline: none;
}

.spacer {
    margin: 2em;
}

.center {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-evenly;
    align-self: center;
}

.nowrap {
//    white-space: nowrap;
}

.pointer {
    cursor: pointer;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.nautile-theme {
    margin: 0;
    padding: 0;

    transition: all 0.3s ease-in-out;
    color: #{$theme-dark};

    font-family: #{$theme-typography-font-family};
    background-color: $theme-background;
    color: $theme-text-primary;

    --ntl-card--width: 17em;
    --ntl-card--height: 17em;
    --ntl-card--height-on-phone: 17em;
    --ntl-card--width-on-phone: 17em;
    --ntl-card--blog-mini-height: 8em;
    // --ntl-card--blog-mini-width: 20em;
    --ntl-card--border-radius: 0.2em;

    --ntl-typography--font-family: #{$theme-typography-font-family};
    --ntl-icon--default-color: #{$theme-text-secondary};

    // --ntl-app-bar--border-top-color:#{$theme-text-secondary};
    // --ntl-app-bar--border-bottom-color:#{$theme-background};
    // --ntl-app-bar--background-color:#{$theme-background};
    --ntl-drawer--background-color: #{$theme-on-primary};
    // --ntl-drawer--background-image

    // --ntl-primary-text-color: #{$theme-text-primary};

    // --ntl-list-item--color: #{$theme-text-primary};
    // --ntl-list--font-size: 1.1em;
    // --ntl-list--font-weight: light;
    // --ntl-list--text-transform: uppercase;
    // --ntl-icon-button--light-color:#{$theme-text-secondary};;
    // --ntl-button--ripple-color: #{$theme-accent};
    // --ntl-button--primary-color: #{$theme-accent};
    // --ntl-button--accent-color: #{$theme-accent-light};
    // --ntl-button--background-color: transparent;
    // --ntl-button--outline-color: #{$theme-accent};
    // --ntl-button--border-radius: 0.1em;

    ntl-accordion-item {
        .video {
            width: 50em;
            height: 28em;
            
            margin: 1em auto;

            @include phone {
                width: 100%;
                height: 10em;            
            }
        }
    }

    font-weight: 300;
    font-size: 1em;
    // line-height: 1.8em;

    .content {
        margin: 0.5em 0;

        @include tablet {
            margin: 0 0.5em;
        }

        @include phone {
            margin: 0 0.5em;
        }

        h1,
        h2,
        h3,
        h4 {
            color: #{$theme-text-secondary};
            font-weight: 500;
        }

        h1,
        h2 {
            text-transform: uppercase;
            font-weight: 700;
        }

        h1 {
            font-size: 2em;
            @include tablet {
                font-size: 1.9em;
            }
            @include phone {
                font-size: 1.7em;
            }
        }

        h2 {
            font-size: 1.8em;
            @include tablet {
                font-size: 1.6em;
            }
            @include phone {
                font-size: 1.5em;
            }
        }

        h3 {
            font-size: 1.7em;
            @include tablet {
                font-size: 1.5em;
            }
            @include phone {
                font-size: 1.4em;
            }
        }

        h4 {
            font-size: 1.4em;
            font-weight: 300;
            // line-height: 1.6em;
            @include tablet {
                font-size: 1.3em;
            }
            @include phone {
                font-size: 1.3em;
            }
        }

        p {
            font-size: 1.1em;
            line-height: 2em;

            img {
                @include flex-box();
                margin: 0 auto;
                max-width: 100%;
            }
        }

        a {
            color: #{$theme-text-accent-dark};
            font-weight: 500;
            text-decoration: none;
        }
    }

    [role="menu"] {
        [role="menu-item"],
        ntl-list-item {
            --ntl-list-item--text-primary-transform: uppercase;
            --ntl-list-item--text-primary-font-weight: 600;
            --ntl-list-item--text-primary-font-size: 0.9em;
            --ntl-list-item--graphic-margin: 0.5em;
        }
    }

    /*Style for cta-bottom*/
    .cta {
        @include flex-box();
        @include align-items(center);
        @include justify-content(space-evenly);

        border-top: 1px dotted $theme-text-secondary;
        border-bottom: 1px dotted $theme-text-secondary;

        margin: 2em 0;

        height: 8em;
        text-align: center;
        color: #{$theme-text-secondary};

        @include tablet {
            @include flex-direction(column);
        }

        @include phone {
            margin: 1em 0;
            @include flex-direction(column);
        }

        .caption {
            font-weight: 700;
            font-size: 2.2em;
            align-items: center;

            @include phone {
                font-size: 1.6em;
            }

            strong {
                color: #{$theme-text-accent-dark};
            }
        }
    }

    .section {
        padding: 2em 0;

        display: grid;
        grid:
            "subtitle img"
            "title    img"
            "content  img"
            / 1fr auto;

        @include desktop {
            &:nth-child(2n) {
                grid:
                    "img subtitle"
                    "img title   "
                    "img content "
                    / auto 1fr;
            }
        }

        @include phone {
            grid:
                "img"
                "subtitle"
                "title"
                "content "
                / minmax(100%, 1fr);
        }

        @include tablet {
            grid:
                "img"
                "subtitle"
                "title"
                "content "
                / minmax(100%, 1fr);
        }

        & > .title {
            grid-area: title;

            font-size: 2.3em;
            font-weight: 700;

            @include phone {
                font-size: 1.5em;
            }

            @include tablet {
                font-size: 2em;
            }
        }

        & > .subtitle {
            grid-area: subtitle;

            font-size: 1.4em;
            font-weight: 400;

            @include phone {
                font-size: 1.2em;
            }

            @include tablet {
                font-size: 1.2em;
            }
        }

        & > .title,
        & > .subtitle {
            color: #{$theme-text-secondary};
            text-transform: uppercase;
        }

        & > .content {
            grid-area: content;
            font-size: 1.1em;
            margin: 0;

            ul {
                line-height: 2em;
            }
        }

        &.list > .content {
            @include flex-direction(row);
            @include flex-wrap(wrap);
            @include justify-content(space-around);
        }

        & > .illustration {
            grid-area: img;
            align-self: center;
            justify-self: center;

            width: 35em;

            text-align: center;
            margin: 0 auto;

            img {
                height: 100%;
            }

            @include phone {
                width: auto;
                img {
                    height: auto;
                    width: 100%;
                }
            }

            @include tablet {
                width: auto;
                img {
                    height: auto;
                    width: 100%;
                }
            }
        }
    }

    //use in before content for more information on subject
    .cta-video {
        @include flex-box();
        justify-content: space-evenly;
        align-items: center;

        border-top: 1px dotted $theme-text-secondary;
        border-bottom: 1px dotted $theme-text-secondary;

        margin: 2em 0;

        @include tablet {
            flex-direction: column;
        }

        @include phone {
            flex-direction: column;
            align-items: start;
            margin: 0;
        }

        .subscriber {
            display: flex;
            flex-direction: column;

            @include tablet {
                align-items: center;
            }

            @include phone {
                align-items: center;
            }

            .title {
                font-size: 2em;
                font-weight: 400;
                color: $theme-primary;

                @include phone {
                    font-size: 1.2em;
                    text-align: center;
                }
            }

            .btn-wrapper {
                display: flex;
                align-items: center;

                @include phone {
                    text-align: center;
                    flex-direction: column;
                }

                .subtitle {
                    font-size: 1.2em;
                    text-transform: uppercase;
                    font-weight: 400;
                    color: $theme-text-primary;
                    margin: 1em 0 1em 1em;

                    .text-primary-dark {
                        color: $theme-accent;
                    }
                }
            }
        }
        @include phone {
            .img-wrapper {
                align-self: center;
            }
        }
    }
}

/*Style for preloader*/
.preloader {
    @include transition(opacity 1s ease-out, visibility 2s ease-out);
    @include opacity(1);

    display: block;
    visibility: visible;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    z-index: 10;

    @include flex-box();
    @include align-items(center);
    @include justify-content(center);

    &.loaded {
        @include opacity(0);
        visibility: hidden;

        //  z-index: -100;
    }
}

// .section-divider {
//     position: relative;
//     height: 0.1em;
//     opacity: 0.5;
//     &:before {
//         position: absolute;
//         content: "";
//         height: 2px;
//         width: 120px;
//         bottom: 0;
//         left: 50%;
//         -webkit-transform: translateX(-50%);
//         transform: translateX(-50%);
//         background: $theme-primary;
//     }
//     &:after {
//         position: absolute;
//         content: "";
//         height: 6px;
//         width: 6px;
//         border-radius: 50%;
//         background: $theme-on-primary;
//         border: 2px solid $theme-primary;
//         box-shadow: 0 0 0 5px $theme-on-primary;
//         bottom: -4px;
//         left: 50%;
//         -webkit-transform: translateX(-50%);
//         transform: translateX(-50%);
//     }
// }
/* General style for tags with the triangle on the left */
.tag {
    // @include transition(all 0.s ease-in-out);

    @include inline-flex();
    @include align-items(center);
    @include flex-direction(row);

    cursor: pointer;
    position: relative;

    font-weight: 500;

    font-size: 0.8em;
    line-height: 2em;
    padding: 0 0.5em 0 0.5em;
    width: auto;
    height: 2em;

    // -webkit-border-radius: 3px 4px 4px 3px;
    // -moz-border-radius: 3px 4px 4px 3px;
    border-radius: 0.1em 0.5em 0.5em 0.1em;

    border-left: 0;
    margin-bottom: 0.5em;
    margin-top: 0.5em;

    margin-left: 1.5em;
    color: #{$theme-text-muted};
    background-color: #{$theme-muted};

    &:hover {
        background: $theme-background-accent-dark;
        border-left: 1px solid #{$theme-background-accent-dark};
        color: #{$theme-on-primary} !important;

        &:before {
            border-right: 1em solid #{$theme-background-accent-dark};
        }
    }

    /* Makes the triangle */
    &:before {
        content: "";
        position: absolute;
        display: block;
        left: -1em;
        width: 0;
        height: 0;
        border-top: 1em solid transparent;
        border-bottom: 1em solid transparent;
        border-right: 1em solid #{$theme-muted};
    }

    /* Makes the circle */
    &:after {
        content: "";
        background-color: #{$theme-on-primary};
        border-radius: 50%;
        width: 4px;
        height: 4px;
        display: block;
        position: absolute;
        left: -0.2em;
        top: 0.85em;
    }

    
}
