$theme-primary: #404040;
$theme-on-primary: #f8f8f8;
$theme-on-primary-semitransparent: #f8f8f877;

$theme-secondary: rgba(183, 28, 28, .8);
$theme-on-secondary: rgba(183, 28, 28, .3);

$theme-surface: #e6dfdf77;
$theme-on-surface: $theme-primary;

$theme-ripple-primary: rgba(250, 200, 200, 0.5);
$theme-ripple-secondary: rgba(183, 88, 88, 0.5);
$theme-ripple-secondary-light: rgba(183, 128, 128, 0.5);

$theme-typography-font-family: unquote("Poppins, sans-serif");
$theme-typography-material-icons: unquote("Material icons");

// $theme-primary-on-light: rgba(0, 0, 0, .55);
// $theme-primary-on-dark: #f8f8f8;

// $theme-semitransparent-light: rgba(200, 200 , 200, .5);
// $theme-semitransparent-dark: rgba(0, 0, 0, .8);


$theme-dark: #222;
$theme-light: rgba(150,150,150,0.1);
$theme-accent-dark: #5e1010;
$theme-accent: #8e1d1d;

$theme-muted: rgba(0, 0, 0, .1);
$theme-disabled: #adadad;

$theme-instagram: #E1306C;
$theme-facebook: #3b5999;
$theme-google: #dd4b39;
$theme-nautile: #ac2a2c;
$theme-linkedin: #0077b5;
$theme-pinterest: #bd081c;
$theme-twitter: #55acee;
$theme-danger: #b71c1c;
$theme-warning: #ff7f00;
$theme-success: #009322;
$theme-info: #476a7b;


$theme-background: #f8f8f8;
$theme-background-secondary: #564545;
$theme-background-light: #fff;
$theme-background-dark: #404040;
$theme-background-accent: #b71c1c;
$theme-background-accent-dark: #8e1d1d;

$theme-background-danger: rgba(183, 28, 28, .9);
$theme-background-warning: rgba(255, 123, 0, .9);
$theme-background-success: rgba(0, 114, 24, .9);
$theme-background-info: #476a7b;
$theme-background-nautile:  #ac2a2c;

$theme-background-disabled: rgba(0, 0, 0, .15);
$theme-background-muted-light: rgba(200, 200, 200, .1);

$theme-text-primary: #404040;
$theme-text-secondary: #564545;
$theme-text-dark: #222;
$theme-text-light: #FFF;
$theme-text-accent: #b71c1c;
$theme-text-accent-dark: #8e1d1d;

$theme-text-danger: #b71c1c;
$theme-text-warning: #ff7f00;
$theme-text-success: #009322;
$theme-text-info: #476a7b;

$theme-text-disabled: #aaa0a0;
$theme-text-muted: rgba(0, 0, 0, .6);

$theme-text-hint-on-light: rgba(0, 0, 0, .38);
$theme-text-hint-on-dark: rgba(255, 255, 255, .8);

$theme-app-bar-height: 7em;
