@mixin box-shadow($shadow1, $shadow2:false, $shadow3:false, $shadow4:false, $shadow5:false, $shadow6:false, $shadow7:false, $shadow8:false) {
    $params: $shadow1;

    @if $shadow2 {
        $params: $shadow1, $shadow2;
    }

    @if $shadow3 !=false {
        $params: $shadow1, $shadow2, $shadow3;
    }

    @if $shadow4 !=false {
        $params: $shadow1, $shadow2, $shadow3, $shadow4;
    }

    @if $shadow5 !=false {
        $params: $shadow1, $shadow2, $shadow3, $shadow4, $shadow5;
    }

    @if $shadow6 !=false {
        $params: $shadow1, $shadow2, $shadow3, $shadow4, $shadow5, $shadow6;
    }

    @if $shadow7 !=false {
        $params: $shadow1, $shadow2, $shadow3, $shadow4, $shadow5, $shadow6, $shadow7
    }

    @if $shadow8 !=false {
        $params: $shadow1, $shadow2, $shadow3, $shadow4, $shadow5, $shadow6, $shadow7, $shadow8
    }

    // @if $shadow9 != false { $params: $shadow1, $shadow2, $shadow3, $shadow4, $shadow5, $shadow6, $shadow7, $shadow8, $shadow9 }

    -webkit-box-shadow: $params;
    -moz-box-shadow: $params;
    box-shadow: $params;
}

@mixin saturate($value) {
    filter: saturate($value);
    -webkit-filter: saturate($value);
    -moz-filter: saturate($value);
}