// --------------------------------------------------
// Flexbox SASS mixins
// The spec: http://www.w3.org/TR/css3-flexbox
// --------------------------------------------------

// Flexbox display
// Using the display property's flex value causes an element to generate a block-level
// flex container box. The inline-flex value causes an element to generate an inline-level
// flex container box.
// Values: flex | inline-flex
// Spec: https://drafts.csswg.org/css-flexbox/#flex-containers
@mixin flex-box() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

// The 'flex' shorthand
// The flex property specifies the components of a flexible length; flex-grow factor,
// flex-shrink factor, and the flex-basis. When an element is a flex item,
// flex is consulted instead of the main size property to determine the main size of the element.
// If an element is not a flex item, flex has no effect.
// Values: See the flex reference for values and default
// Spec: https://drafts.csswg.org/css-flexbox/#flex-property
@mixin flex($fg: 1, $fs: 0, $fb: auto) {

    // Set a variable to be used by box-flex properties
    $fg-boxflex: $fg;

    // Box-Flex only supports a flex-grow value so lets grab the
    // first item in the list and just return that.
    @if type-of($fg)=='list' {
        $fg-boxflex: nth($fg, 1);
    }

    -webkit-box: $fg-boxflex;
    -moz-box: $fg-boxflex;
    -webkit-flex: $fg $fs $fb;
    -ms-flex: $fg $fs $fb;
    flex: $fg $fs $fb;
}

@mixin inline-flex {
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

// Flex Flow Direction
// The flex-direction property specifies how flex items are placed in the flex container,
// by setting the direction of the flex container's main axis. This determines the direction
// in which flex items are laid out in.
// Values: row (default) | row-reverse | column | column-reverse
// Spec: https://drafts.csswg.org/css-flexbox/#flex-direction-property

@mixin flex-direction($value: row) {
    @if $value==row-reverse {
        -webkit-box-direction: reverse;
        -webkit-box-orient: horizontal;
        -moz-box-direction: reverse;
        -moz-box-orient: horizontal;
    }

    @else if $value==column {
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
        -moz-box-direction: normal;
        -moz-box-orient: vertical;
    }

    @else if $value==column-reverse {
        -webkit-box-direction: reverse;
        -webkit-box-orient: vertical;
        -moz-box-direction: reverse;
        -moz-box-orient: vertical;
    }

    @else {
        -webkit-box-direction: normal;
        -webkit-box-orient: horizontal;
        -moz-box-direction: normal;
        -moz-box-orient: horizontal;
    }

    -webkit-flex-direction: $value;
    -ms-flex-direction: $value;
    flex-direction: $value;
}

// Shorter version:
@mixin flex-dir($args...) {
    @include flex-direction($args...);
}

// Flex Line Wrapping
// The flex-wrap property controls whether the flex container is single-lined or multi-lined
// and the direction of the cross-axis, which determines the direction in which the new lines are stacked in.
// Values: nowrap (default) | wrap | wrap-reverse
// Spec: https://drafts.csswg.org/css-flexbox/#flex-wrap-property
@mixin flex-wrap($value: nowrap) {
    // No Webkit/FF Box fallback.
    -webkit-flex-wrap: $value;

    @if $value==nowrap {
        -ms-flex-wrap: none;
    }

    @else {
        -ms-flex-wrap: $value;
    }

    flex-wrap: $value;
}

// Flex Direction and Wrap
// The flex-flow property is shorthand for setting the flex-direction and flex-wrap properties,
// which together define the flex container's main and cross axes.
// Values: row (default) | nowrap
// Spec: https://drafts.csswg.org/css-flexbox/#flex-flow-property
@mixin flex-flow($values: (row nowrap)) {
    // No Webkit/FF Box fallback.
    -webkit-flex-flow: $values;
    -ms-flex-flow: $values;
    flex-flow: $values;
}

// Display Order
// The order property controls the order in which flex items appear within their flex container,
// by assigning them to ordinal groups.
// Value: any integer (0 is the default)
// Spec: https://drafts.csswg.org/css-flexbox/#order-property
@mixin order($int: 0) {
    -webkit-box-ordinal-group: $int + 1;
    -moz-box-ordinal-group: $int + 1;
    -webkit-order: $int;
    -ms-flex-order: $int;
    order: $int;
}

// Flex grow factor
// The flex-grow property sets the flex grow factor. Negative numbers are invalid.
// Value: any integer (1 is the default)
// Spec: https://drafts.csswg.org/css-flexbox/#flex-grow-property
@mixin flex-grow($int: 1) {
    -webkit-box-flex: $int;
    -moz-box-flex: $int;
    -webkit-flex-grow: $int;
    -ms-flex: $int;
    flex-grow: $int;
}

// Flex shrink
// The flex-shrink property sets the flex shrink factor. Negative numbers are invalid.
// Value: any integer (0 is the default)
// Spec: https://drafts.csswg.org/css-flexbox/#flex-shrink-property
@mixin flex-shrink($int: 0) {
    -webkit-flex-shrink: $int;
    -moz-flex-shrink: $int;
    -ms-flex: $int;
    flex-shrink: $int;
}

// Flex basis
// The flex-basis property sets the flex basis. Negative lengths are invalid.
// Values: See the flex-basis reference (the default is auto)
// Spec: https://drafts.csswg.org/css-flexbox/#flex-basis-property
@mixin flex-basis($value: auto) {
    -webkit-flex-basis: $value;
    flex-basis: $value;
}

// Axis Alignment
// The justify-content property aligns flex items along the main axis of the current line of
// the flex container. This is done after any flexible lengths and any auto margins have been resolved.
//§ Typically, it helps to distribute extra free space leftover when either all the flex items on a
// line are inflexible, or are flexible but have reached their maximum size.
// It also exerts some control over the alignment of items when they overflow the line.
// Note: space-* values not supported in older syntaxes.
// Values: flex-start (default) | flex-end | center | space-between | space-around
// Spec: https://drafts.csswg.org/css-flexbox/#justify-content-property
@mixin justify-content($value: flex-start) {
    @if $value==flex-start {
        -webkit-box-pack: start;
        -moz-box-pack: start;
        -ms-flex-pack: start;
    }

    @else if $value==flex-end {
        -webkit-box-pack: end;
        -moz-box-pack: end;
        -ms-flex-pack: end;
    }

    @else if $value==space-between {
        -webkit-box-pack: justify;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
    }

    @else if $value==space-around {
        -ms-flex-pack: distribute;
    }

    @else {
        -webkit-box-pack: $value;
        -moz-box-pack: $value;
        -ms-flex-pack: $value;
    }

    -webkit-justify-content: $value;
    justify-content: $value;
}

// Shorter version:
@mixin flex-just($args...) {
    @include justify-content($args...);
}

// // Packing Flex Lines
// The align-content property aligns a flex container's lines within the flex container when 
// there is extra space in the cross-axis, similar to how justify-content aligns individual items
// within the main axis. Note that this property has no effect when the flexbox has only a single line.
// Values: flex-start | flex-end | center | space-between | space-around | stretch (default)
// Spec: https://drafts.csswg.org/css-flexbox/#align-content-property
@mixin align-content($value: stretch) {
    // No Webkit Box Fallback.
    -webkit-align-content: $value;

    @if $value==flex-start {
        -ms-flex-line-pack: start;
    }

    @else if $value==flex-end {
        -ms-flex-line-pack: end;
    }

    @else {
        -ms-flex-line-pack: $value;
    }

    align-content: $value;
}

// Cross-axis Alignment
// Flex items can be aligned in the cross axis of the current line of the flex container, 
// similar to justify-content but in the perpendicular direction. align-items sets the default alignment 
// for all of the flex container's items, including anonymous flex items. align-self allows this default 
// alignment to be overridden for individual flex items. (For anonymous flex items, align-self always matches 
// the value of align-items on their associated flex container.)
// Values: flex-start | flex-end | center | baseline | stretch (default)
// Spec: https://drafts.csswg.org/css-flexbox/#align-items-property
@mixin align-items($value: stretch) {
    @if $value==flex-start {
        -webkit-box-align: start;
        -moz-box-align: start;
        -ms-flex-align: start;
    }

    @else if $value==flex-end {
        -webkit-box-align: end;
        -moz-box-align: end;
        -ms-flex-align: end;
    }

    @else {
        -webkit-box-align: $value;
        -moz-box-align: $value;
        -ms-flex-align: $value;
    }

    -webkit-align-items: $value;
    align-items: $value;
}

// Cross-axis Alignment
// Values: auto (default) | flex-start | flex-end | center | baseline | stretch
// Spec: https://drafts.csswg.org/css-flexbox/#align-items-property
@mixin align-self($value: auto) {
    // No Webkit Box Fallback.
    -webkit-align-self: $value;

    @if $value==flex-start {
        -ms-flex-item-align: start;
    }

    @else if $value==flex-end {
        -ms-flex-item-align: end;
    }

    @else {
        -ms-flex-item-align: $value;
    }

    align-self: $value;
}